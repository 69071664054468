import localForage from "localforage";

export enum StoragePrefix {
  AUTH = "Auth",
  CART = "Cart",
  CATALOG = "Catalog",
  ORDERS = "Orders",
}

const cacheName = "INNOVALIVING_CATALOG_STORAGE"

function getLocalStorageKey(prefix: StoragePrefix, key: string) {
  const prefixVersion = {
    [StoragePrefix.AUTH]: 1,
    [StoragePrefix.CART]: 1,
    [StoragePrefix.CATALOG]: 1,
    [StoragePrefix.ORDERS]: 1,
  }
  const version = prefixVersion[prefix]
  return `${prefix}_${version}_${key}`
}

export function lsSet(prefix: StoragePrefix, key: string, value: any) {
  const lsKey = getLocalStorageKey(prefix, key)
  window.localStorage.setItem(lsKey, JSON.stringify(value))
}

export function lsGet<T>(prefix: StoragePrefix, key: string): T | null {
  const lsKey = getLocalStorageKey(prefix, key)
  const json = window.localStorage.getItem(lsKey)
  if (!json) return null
  return JSON.parse(json) as T
}


export async function cacheSet(prefix: StoragePrefix, key: string, value: any) {
  const lsKey = getLocalStorageKey(prefix, key)
  const storage = await caches.open(cacheName);
  await storage.put(lsKey, new Response(JSON.stringify(value)))
}


export async function cacheGet<T>(prefix: StoragePrefix, key: string): Promise<T | null> {
  const lsKey = getLocalStorageKey(prefix, key)
  const storage = await caches.open(cacheName);
  const response = await (await storage.match(lsKey))?.json()
  if (!response) return null
  return JSON.parse(response) as T
}

export async function storageSet(prefix: StoragePrefix, key: string, value: any) {
  const lsKey = getLocalStorageKey(prefix, key)
  await localForage.setItem(lsKey, value)
}


export async function storageGet<T>(prefix: StoragePrefix, key: string): Promise<T | null> {
  const lsKey = getLocalStorageKey(prefix, key)
  const json = await localForage.getItem(lsKey)
  if (!json) return null
  return json as T
}

