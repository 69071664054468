import { notification, Input } from "antd"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { Photo, Product, ProductId } from "../../models/Product"
import { addToCart as addToCartAction } from "../../store/cartSlice"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import {
  FunctionComponent,
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useState,
} from "react"
import priceFormatter from "../../utils/priceFormatter"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useParams, generatePath } from "react-router-dom"
import { findProduct } from "../../store/catalogSlice"
import i18n from "../../i18n"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CubeTransparentIcon,
} from "@heroicons/react/outline"
import routes from "../../routes"
import { buildImageUrl, getCrossOriginSetting } from "../../utils/images"
import {
  getOriginalProductPrice,
  getProductPrice,
} from "../../utils/getProductPrice"
import { useSwipeable } from "react-swipeable"
import { Localized } from "../../models"

interface ImagePopupProps extends HTMLAttributes<HTMLDivElement> {
  product: Product
  selectedItem?: number
  onClose: (e: SyntheticEvent) => void
}

const ImagePopup: FunctionComponent<ImagePopupProps> = ({
  product,
  selectedItem = 0,
  onClose,
  ...props
}: ImagePopupProps) => {
  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-25"
      onClick={onClose}
      {...props}
    >
      <div
        className="absolute bg-white max-h-[80vh] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl"
        onClick={(e) => e.stopPropagation()}
      >
        <Carousel
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          selectedItem={selectedItem}
        >
          {[
            product.cover_photo,
            ...product.photos.map((photo: Photo) => photo.path),
          ].map((photo) => (
            <div key={photo}>
              <img
                src={buildImageUrl(photo)}
                crossOrigin={getCrossOriginSetting()}
                alt={product.name[i18n.language]}
                className="max-h-[80vh]"
                style={{ width: "auto" }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default function Index() {
  const history = useHistory()
  const { t } = useTranslation()
  const catalog = useAppSelector((state) => state.catalog)
  // Display products in a grid
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id: string }>()
  const product = findProduct(catalog, parseInt(id))

  const [selectedItem, setSelectedItem] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false)

  const [quantity, setQuantity] = useState(product ? product.lot_size : 1)
  const addToCart = async (
    productId: ProductId,
    quantity: number,
    name: Localized<string>,
    code: string,
  ) => {
    if (quantity < 1) return
    await dispatch(
      addToCartAction({ product: productId, quantity, name, code }),
    )
    notification.success({ message: t("cart.addedToCart") })
    setQuantity(product ? product.lot_size : 1)
  }

  const goBack = () =>
    history.push(generatePath(routes.products.index) + "?restore-scroll")
  const productIndex = useAppSelector((state) =>
    state.catalog._allFilteredProducts?.findIndex((p) => p.id === Number(id)),
  ) as number
  const hasPrev = productIndex !== 0
  const hasNext = useAppSelector(
    (state) => productIndex !== state.catalog._allFilteredProducts!.length - 1,
  )
  const prevProduct = useAppSelector((state) =>
    state.catalog._allFilteredProducts && hasPrev
      ? generatePath(routes.products.show, {
          id: state.catalog._allFilteredProducts[productIndex - 1]?.id,
        })
      : "",
  )
  const nextProduct = useAppSelector((state) =>
    state.catalog._allFilteredProducts && hasNext
      ? generatePath(routes.products.show, {
          id: state.catalog._allFilteredProducts[productIndex + 1]?.id,
        })
      : "",
  )

  useEffect(() => window.scrollTo(0, 0), [])

  const [slide, setSlide] = useState(0)

  useEffect(() => {
    setSelectedItem(0)
    setSlide(0)
  }, [id])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => (hasNext ? history.push(nextProduct) : void 0),
    onSwipedRight: () => (hasPrev ? history.push(prevProduct) : void 0),
    delta: 150,
  })

  return (
    <>
      <div className="sticky z-30 flex flex-col justify-between w-full gap-4 p-4 top-16 bg-gray-50 md:flex-row">
        <button
          type="button"
          className="flex flex-row items-center space-x-2 font-medium"
          onClick={goBack}
        >
          <ArrowLeftIcon className="w-6 h-6"></ArrowLeftIcon>
          <span>{t("actions.goBack")}</span>
        </button>
        <div className="flex justify-between gap-8 md:col-span-2">
          {hasPrev ? (
            <Link
              to={prevProduct}
              className="flex flex-row items-center space-x-2 font-medium"
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </Link>
          ) : (
            <div></div>
          )}
          {hasNext ? (
            <Link
              to={nextProduct}
              className="flex flex-row items-center space-x-2 font-medium"
            >
              <ArrowRightIcon className="w-6 h-6" />
            </Link>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {product && popupOpen ? (
        <ImagePopup
          product={product}
          selectedItem={selectedItem}
          onClose={() => setPopupOpen(false)}
        />
      ) : null}
      {!product && (
        <>
          <div className="grid items-center justify-center w-full h-full text-xl">
            <h1>{t("products.show.notFound")}</h1>
          </div>
        </>
      )}
      {product && (
        <div
          className="flex items-center justify-center flex-grow w-5/6 pt-24 mx-auto md:w-full md:h-full md:pt-0"
          {...swipeHandlers}
        >
          <div className="grid w-full max-w-screen-lg grid-cols-1 gap-8 py-16 md:gap-6 justify-items-center md:items-center">
            <div className="flex flex-col gap-4">
              <div className="w-full p-8 bg-white rounded-lg shadow">
                <Carousel
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={slide}
                  onChange={setSlide}
                >
                  {[
                    product.cover_photo,
                    ...product.photos.map((photo: Photo) => photo.path),
                  ].map((photo, j) => (
                    <button
                      key={photo}
                      onClick={() => {
                        setPopupOpen(true)
                        setSelectedItem(j)
                      }}
                    >
                      <img
                        src={buildImageUrl(photo)}
                        crossOrigin={getCrossOriginSetting()}
                        alt={product.name[i18n.language]}
                      />
                    </button>
                  ))}
                </Carousel>
              </div>
              <div className="w-full overflow-x-auto">
                <div className="flex">
                  {[
                    product.cover_photo,
                    ...product.photos.map((photo: Photo) => photo.path),
                  ].map((photo, j) => (
                    <button
                      key={photo}
                      onClick={() => setSlide(j)}
                      className="relative aspect-1 min-w-[20%]"
                    >
                      <span className="absolute flex items-center justify-center bg-white rounded shadow inset-1">
                        <img
                          src={buildImageUrl(photo)}
                          crossOrigin={getCrossOriginSetting()}
                          alt={product.name[i18n.language]}
                          className="object-contain w-5/6 h-5/6"
                        />
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="w-5/6 mx-auto space-y-4">
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <div className="text-sm">
                      {product.category.name[i18n.language]}
                    </div>
                    <h1 className="text-lg font-bold">
                      {product.name[i18n.language]}
                    </h1>
                    <div className="text-sm text-gray-500">{product.code}</div>
                  </div>
                </div>
                <div className="text-2xl text-gray-700">
                  <span
                    className={product.special_price ? " line-through" : ""}
                  >
                    {priceFormatter.format(
                      getOriginalProductPrice(product, quantity),
                    )}
                  </span>
                  &nbsp;
                  {product.special_price
                    ? priceFormatter.format(getProductPrice(product, quantity))
                    : ""}
                </div>
                <div className="text-xl">
                  {product.special_price ? (
                    <div className="font-bold">
                      {t("product.specialPrice")}:{" "}
                      {priceFormatter.format(product.special_price)}
                    </div>
                  ) : (
                    product.large_quantities_price && (
                      <div className="italic">
                        {t("product.largeQuantitiesThreshold")}{" "}
                        {product.large_quantities_threshold}{" "}
                        {t("product.largeQuantitiesPrice")}:{" "}
                        {priceFormatter.format(product.large_quantities_price)}
                      </div>
                    )
                  )}
                </div>
                {product.availability[i18n.language] === "Available" ||
                product.availability[i18n.language] === "Disponibile" ? (
                  <div className="flex items-center mt-6 text-lg">
                    <CheckIcon
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      aria-hidden="true"
                    />
                    <div className="ml-2 text-gray-500">
                      {t("product.available")}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center mt-6 text-lg">
                    <div className="ml-2 text-gray-500">
                      {product.availability[i18n.language]}
                    </div>
                  </div>
                )}
                <div className="space-x-3">
                  <div className="inline-flex">
                    <Input
                      type="number"
                      size="large"
                      value={quantity}
                      min={product.lot_size}
                      step={product.lot_size}
                      onChange={(event) =>
                        setQuantity(Number(event.target.value))
                      }
                      className="border-t border-b border-l rounded-l border-mygreen-800"
                    ></Input>
                    <div className="flex text-4xl leading-5">
                      <button
                        onClick={() =>
                          setQuantity(
                            quantity > product.lot_size
                              ? quantity - product.lot_size
                              : product.lot_size,
                          )
                        }
                        className="w-12 h-12 px-3 text-white border border-l-0 bg-mygreen-500 border-mygreen-800"
                      >
                        -
                      </button>
                      <button
                        onClick={() => setQuantity(quantity + product.lot_size)}
                        className="w-12 h-12 px-2 pb-1 text-white border border-l-0 rounded-r bg-mygreen-500 border-mygreen-800"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-500 hover:bg-mygreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() =>
                      addToCart(
                        product.id,
                        quantity,
                        product.name,
                        product.code,
                      )
                    }
                  >
                    {t("actions.addToCart")}
                  </button>
                </div>
              </div>
              <div className="flex flex-col w-5/6 mx-auto mt-4 space-y-4 md:mt-8">
                {product.assortment && (
                  <div className="italic">
                    {product.assortment}{" "}
                    {t("product.assortmentItems", { count: product.lot_size })}
                  </div>
                )}
                {product.sizes.map((size) => (
                  <div
                    key={JSON.stringify(size)}
                    className="flex flex-row items-center"
                  >
                    <CubeTransparentIcon className="inline w-6 h-6"></CubeTransparentIcon>
                    {Object.entries(size).map(([k, v]) => {
                      // Allow only "w", "h" and "l" properties
                      if (!["w", "h", "l"].includes(k)) {
                        return <></>
                      }
                      return (
                        <span key={k} className="ml-3">
                          {k.toUpperCase()}: {v}
                        </span>
                      )
                    })}
                  </div>
                ))}
                <div>{product.description[i18n.language]}</div>
                <div>{product.note[i18n.language]}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
