import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Category, Product, ProductId, Tag } from "../models/Product"
import i18n from "../i18n"

interface CatalogState {
  products: Product[] | null
  categories: Category[] | null
  tags: Tag[] | null
  _productsLookup: Record<ProductId, Product>
  _allFilteredProducts: Product[] | null
  filteredProducts: Product[] | null
  filters: {
    categories?: number[]
    tags?: number[]
    text?: string
  }
}
const ITEMS = 16
export const catalogSlice = createSlice({
  name: "catalog",
  initialState: {
    products: null,
    categories: null,
    tags: null,
    filteredProducts: null,
    filters: {
      categories: [] as number[],
      tags: [] as number[],
      text: "",
    },
  } as CatalogState,
  reducers: {
    setCatalog: (
      state,
      action: PayloadAction<{
        products: Product[]
        categories: Category[]
        tags: Tag[]
      }>,
    ) => {
      state.products = [...action.payload.products]
      state._allFilteredProducts = [...state.products.slice(0, ITEMS)]
      state.filteredProducts = [...state.products]
      state.categories = [...action.payload.categories]
      state.tags = [...action.payload.tags]
      state._productsLookup = {}
      for (const product of state.products) {
        state._productsLookup[product.id] = product
      }
    },
    filterProducts: (
      state,
      action: PayloadAction<{
        categories?: number[]
        tags?: number[]
        text?: string
        onlyAvailable?: boolean
        prevLength?: number
      }>,
    ) => {
      state.filters = action.payload
      const text = action.payload.text?.trim().toLowerCase() ?? ""
      if (!state.products) return
      state._allFilteredProducts = state.products
        .filter((p) => p.visible)
        .filter(
          (p) =>
            !action.payload.categories?.length ||
            action.payload.categories.includes(p.category.id),
        )
        .filter(
          (p) =>
            !action.payload.tags?.length ||
            action.payload.tags?.some((t) =>
              p.tags.map((t) => t.id).includes(t),
            ),
        )
        .filter(
          (p) =>
            !action.payload.onlyAvailable ||
            p.availability[i18n.language] === "Available" ||
            p.availability[i18n.language] === "Disponibile",
        )
        .filter(
          (p) =>
            !text.length ||
            [
              p.name[i18n.language],
              p.description[i18n.language],
              p.category.name[i18n.language],
              p.code,
            ].some((fullText) => fullText.toLowerCase().includes(text)),
        )
      state.filteredProducts = state._allFilteredProducts.slice(
        0,
        Math.max(ITEMS, action.payload.prevLength ?? 0),
      )
    },
    loadMore: (state) => {
      state.filteredProducts =
        state._allFilteredProducts?.slice(
          0,
          ITEMS + (state.filteredProducts?.length ?? 0),
        ) ?? null
    },
  },
})

export function findProduct(
  state: CatalogState,
  pId: ProductId,
): Product | null {
  return pId in state._productsLookup ? state._productsLookup[pId] : null
}

export const { setCatalog, filterProducts, loadMore } = catalogSlice.actions

export default catalogSlice.reducer
