import { Product } from "../models/Product"

export function getOriginalProductPrice(
  product: Product,
  quantity: number,
): number {
  if (
    product.large_quantities_price &&
    quantity >= product.large_quantities_threshold
  ) {
    return product.large_quantities_price
  }
  return product.price
}

export function getProductPrice(product: Product, quantity: number): number {
  if (product.special_price) {
    return product.special_price
  }
  return getOriginalProductPrice(product, quantity)
}
