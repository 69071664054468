import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { lsGet, lsSet, StoragePrefix } from "../utils/localstorage"

interface AuthState {
  ready: boolean
  loggedIn: boolean
  accessToken: string | null
  refreshToken: string | null
}
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    ready: false,
    loggedIn: false,
    accessToken: null,
    refreshToken: null,
  } as AuthState,
  reducers: {
    init(state) {
      const accessToken = lsGet<string>(StoragePrefix.AUTH, "accessToken")
      const refreshToken = lsGet<string>(StoragePrefix.AUTH, "refreshToken")
      if (accessToken && refreshToken) {
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        state.loggedIn = true
      }
      state.ready = true
    },
    initOffline(state) {
      // do not change loggedIn state as otherwise the entire application becomes blocked while offline
      // state.loggedIn = false
      state.ready = true
    },
    login(
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>,
    ) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      lsSet(StoragePrefix.AUTH, "accessToken", action.payload.accessToken)
      lsSet(StoragePrefix.AUTH, "refreshToken", action.payload.refreshToken)
      state.loggedIn = true
    },
    logout(state) {
      state.accessToken = null
      state.refreshToken = null
      lsSet(StoragePrefix.AUTH, "accessToken", null)
      lsSet(StoragePrefix.AUTH, "refreshToken", null)
      state.loggedIn = false
    },
  },
})

export const { init, initOffline, login, logout } = authSlice.actions
export default authSlice.reducer
