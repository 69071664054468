import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { notification } from "antd"

interface ErrorState {
  error: string | null
}
export const errorSlice = createSlice({
  name: "error",
  initialState: {
    error: null,
  } as ErrorState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      notification.error({
        key: "error",
        message: "Errore",
        description: action.payload,
        duration: 0,
      })
    },
  },
})

export const { setError } = errorSlice.actions

export default errorSlice.reducer
