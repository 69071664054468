import { notification } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../hooks"
import routes from "../../routes"
import { login as authLogin } from "../../api/Auth"
import { login as authSliceLogin } from "../../store/authSlice"
import { t } from "i18next"

export default function Login() {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [form, setForm] = useState({
    email: "",
    password: "",
  })
  const dispatch = useAppDispatch()
  const history = useHistory()
  const login = () =>
    authLogin(form.email, form.password)
      .then((response) => {
        dispatch(
          authSliceLogin({
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
          }),
        )
        notification.success({
          message: "Login successful",
        })
        history.replace(routes.index)
      })
      .catch(setError)

  return (
    <>
      <div className="flex flex-grow flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src="/images/logo-black-sidebar.png"
            className="w-4/5 mx-auto mt-6 text-center"
            alt="innovaliving dark logo"
          ></img>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("auth.email")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm((form) => ({ ...form, email: e.target.value }))
                    }
                    required
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("auth.password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={form.password}
                    onChange={(e) =>
                      setForm((form) => ({ ...form, password: e.target.value }))
                    }
                    required
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {error && (
                <div className="text-red-500">
                  {error.message?.includes("401")
                    ? t("invalid-credentials")
                    : t("generic-error")}
                </div>
              )}

              <div>
                <button
                  type="button"
                  onClick={login}
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-500 hover:bg-mygreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("auth.login")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
