import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { lsGet, lsSet, StoragePrefix } from "../utils/localstorage"
import { CartOrder } from "./cartSlice"

export interface OrderIntent extends CartOrder {
  uuid: string
  created_at: string
}

interface PendingOrdersState {
  orders: Array<OrderIntent>
}

export const pendingOrders = createSlice({
  name: "cart",
  initialState: {
    orders: [],
  } as PendingOrdersState,
  reducers: {
    loadFromLocalStorage: (state) => {
      const orders = lsGet<OrderIntent[]>(StoragePrefix.ORDERS, "orders")
      if (!orders) return
      state.orders = orders
    },
    addOrder: (state, action: PayloadAction<OrderIntent>) => {
      state.orders.push(action.payload)
      lsSet(StoragePrefix.ORDERS, "orders", state.orders)
    },
    clearOrders: (state) => {
      state.orders = []
      lsSet(StoragePrefix.ORDERS, "orders", [])
    },
    deleteOrder: (state, action: PayloadAction<{ index: number }>) => {
      state.orders.splice(action.payload.index, 1)
      lsSet(StoragePrefix.ORDERS, "orders", state.orders)
    },
  },
})

export const { addOrder, loadFromLocalStorage, clearOrders, deleteOrder } =
  pendingOrders.actions

export default pendingOrders.reducer
