import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PageScrollState {
  pageScroll?: number
  paginationItems?: number
}
export const pageScroll = createSlice({
  name: "error",
  initialState: {} as PageScrollState,
  reducers: {
    setPageScroll: (state, action: PayloadAction<number>) => {
      state.pageScroll = action.payload
    },
    setPaginationItems: (state, action: PayloadAction<number>) => {
      state.paginationItems = action.payload
    },
  },
})

export const { setPageScroll, setPaginationItems } = pageScroll.actions

export default pageScroll.reducer
