import { post } from "./API"

interface LoginResponse {
  token_type: "Bearer"
  access_token: string
  expires_in: number
  refresh_token: string
}

export function login(email: string, password: string): Promise<LoginResponse> {
  return post("/oauth/token", { username: email, password })
}
