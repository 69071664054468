import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Localized } from "../models"
import { ProductId } from "../models/Product"
import { lsGet, lsSet, StoragePrefix } from "../utils/localstorage"

export interface OrderRow {
  productId: ProductId
  name: Localized<string>
  code: string
  quantity: number
}

export interface CartOrder {
  rows: OrderRow[]
  customer: string
  delivery: string
  payment: string
  sdi: string
  notes: string
}

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    rows: [],
    customer: "",
    delivery: "",
    payment: "",
    sdi: "",
    notes: "",
  } as CartOrder,
  reducers: {
    loadFromLocalStorage: (state) => {
      const rows = lsGet<OrderRow[]>(StoragePrefix.CART, "rows")
      const customer = lsGet<string>(StoragePrefix.CART, "customer")
      const delivery = lsGet<string>(StoragePrefix.CART, "delivery")
      const payment = lsGet<string>(StoragePrefix.CART, "payment")
      const sdi = lsGet<string>(StoragePrefix.CART, "sdi")
      if (!rows) return
      state.rows = rows
      state.customer = customer ?? ""
      state.delivery = delivery ?? ""
      state.payment = payment ?? ""
      state.sdi = sdi ?? ""
      state.notes = sdi ?? ""
    },
    addToCart: (
      state,
      action: PayloadAction<{
        product: ProductId
        quantity: number
        name: Localized<string>
        code: string
      }>,
    ) => {
      const row = state.rows.find(
        ({ productId }) => action.payload.product === productId,
      )
      if (row) row.quantity += action.payload.quantity
      else
        state.rows.push({
          productId: action.payload.product,
          name: action.payload.name,
          code: action.payload.code,
          quantity: action.payload.quantity,
        })
      lsSet(StoragePrefix.CART, "rows", state.rows)
    },
    removeCartItem: (state, action: PayloadAction<{ index: number }>) => {
      state.rows.splice(action.payload.index, 1)
      lsSet(StoragePrefix.CART, "rows", state.rows)
    },
    increaseCartItemQuantity: (
      state,
      action: PayloadAction<{ index: number; lot_size: number }>,
    ) => {
      state.rows[action.payload.index].quantity += action.payload.lot_size
      lsSet(StoragePrefix.CART, "rows", state.rows)
    },
    decreaseCartItemQuantity: (
      state,
      action: PayloadAction<{ index: number; lot_size: number }>,
    ) => {
      state.rows[action.payload.index].quantity -= action.payload.lot_size
      lsSet(StoragePrefix.CART, "rows", state.rows)
    },
    setCustomer: (state, action: PayloadAction<string>) => {
      state.customer = action.payload
      lsSet(StoragePrefix.CART, "customer", state.customer)
    },
    setDelivery: (state, action: PayloadAction<string>) => {
      state.delivery = action.payload
      lsSet(StoragePrefix.CART, "delivery", state.delivery)
    },
    setPayment: (state, action: PayloadAction<string>) => {
      state.payment = action.payload
      lsSet(StoragePrefix.CART, "payment", state.payment)
    },
    setSdi: (state, action: PayloadAction<string>) => {
      state.sdi = action.payload
      lsSet(StoragePrefix.CART, "sdi", state.sdi)
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
      lsSet(StoragePrefix.CART, "notes", state.notes)
    },
    clearCart: (state) => {
      state.rows = []
      state.customer = ""
      state.delivery = ""
      state.payment = ""
      state.sdi = ""
      state.notes = ""
      lsSet(StoragePrefix.CART, "rows", state.rows)
      lsSet(StoragePrefix.CART, "customer", state.customer)
      lsSet(StoragePrefix.CART, "delivery", state.delivery)
      lsSet(StoragePrefix.CART, "payment", state.payment)
      lsSet(StoragePrefix.CART, "sdi", state.sdi)
      lsSet(StoragePrefix.CART, "sdi", state.notes)
    },
  },
})

export const {
  loadFromLocalStorage,
  addToCart,
  clearCart,
  setCustomer,
  setDelivery,
  setPayment,
  setSdi,
  setNotes,
  removeCartItem,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
} = cartSlice.actions

export default cartSlice.reducer
