const routes = {
  index: "/",
  cart: "/cart",
  pendingOrders: "/pending-orders",
  user: {
    profile: "/profile",
  },
  auth: {
    login: "/login",
  },
  products: {
    index: "/products",
    show: "/products/:id",
  },
}
export default routes
