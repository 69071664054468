import { notification, Badge } from "antd"
import React, { useEffect, useState, Fragment, useRef } from "react"
import {
  Switch,
  Route,
  RouteProps,
  useRouteMatch,
  Link,
  useHistory,
} from "react-router-dom"
import routes from "./routes"
import useOnlineStatus from "./hooks/useOnlineStatus"
import { setup as setupApi } from "./api/API"
import { all as allProducts } from "./api/Products"
import { all as allCategories } from "./api/Categories"
import { all as allTags } from "./api/Tags"
import { useAppDispatch, useAppSelector } from "./hooks"
import { setCatalog } from "./store/catalogSlice"
import {
  init as authInit,
  initOffline as authInitOffline,
} from "./store/authSlice"
import AuthLogin from "./views/auth/Login"
import AuthLogout from "./views/auth/Logout"
import Cart from "./views/Cart"
import Categories from "./views/Categories"
import ProductsIndex from "./views/products/Index"
import ProductsShow from "./views/products/Show"
import config from "./config"
import { loadFromLocalStorage as loadCartFromLocalStorage } from "./store/cartSlice"
import { loadFromLocalStorage as loadPendingOrdersFromLocalStorage } from "./store/pendingOrdersSlice"
import { storageGet, storageSet, StoragePrefix } from "./utils/localstorage"
import { Category, Product, Tag } from "./models/Product"
import { useTranslation } from "react-i18next"
import PendingOrders from "./views/PendingOrders"

// Tailwind UI
import { Dialog, Menu, Transition } from "@headlessui/react"
import {
  HomeIcon,
  MenuAlt2Icon,
  CollectionIcon,
  ShoppingCartIcon,
  ClipboardListIcon,
  XIcon,
  UserIcon,
  RefreshIcon,
} from "@heroicons/react/outline"
import i18n from "./i18n"
import { t } from "i18next"
import { checkCachedImages, precacheImages } from "./utils/images"

const navigation = [
  { name: t("routes.dashboard"), to: routes.index, icon: HomeIcon },
  {
    name: t("routes.catalog"),
    to: routes.products.index,
    icon: CollectionIcon,
  },
]
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ")
}

function App() {
  setupApi(`${config.apiHost}/api`)
  const dispatch = useAppDispatch()
  const onlineStatus = useOnlineStatus()
  const pendingOrders = useAppSelector((state) => state.pendingOrders)
  const cart = useAppSelector((state) => state.cart)
  const auth = useAppSelector((state) => state.auth)
  const error = useAppSelector((state) => state.error.error)
  let timeout: NodeJS.Timeout

  const downloading = useRef(false)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadCartFromLocalStorage())
    dispatch(loadPendingOrdersFromLocalStorage())
  }, [dispatch])

  async function downloadCatalog() {
    const products = await allProducts()
    const categories = await allCategories()
    const tags = await allTags()
    await dispatch(setCatalog({ products, categories, tags }))
    await storageSet(StoragePrefix.CATALOG, "products", products)
    await storageSet(StoragePrefix.CATALOG, "categories", categories)
    await storageSet(StoragePrefix.CATALOG, "tags", tags)

    downloading.current = true
    // Download images in a non-blocking way
    checkCachedImages(products, categories)
  }

  async function emptyCacheAndDownloadCatalog() {
    const cacheKeys = (await caches.keys())
      .filter((cacheName) => cacheName.startsWith("images"))

    for (const cacheName of cacheKeys) {
      console.log(`Deleting cache ${cacheName}`)
      await caches.delete(cacheName)
    }
    await downloadCatalog()
    timeout = setTimeout(async () => {
      if (!onlineStatus) return
      const products = await storageGet<Product[]>(StoragePrefix.CATALOG, "products")
      const categories = await storageGet<Category[]>(StoragePrefix.CATALOG, "categories")
      if (!categories || !products) return

      console.log(`downloading: ${downloading.current}`)
      // Prevent double executions
      if (!downloading.current) return
      console.log("Firing the check of cached images")
      await checkCachedImages(products, categories, true)
      notification.success({
        message: t("messages.catalog.downloaded"),
        description: t("messages.catalog.offlineReady"),
        duration: null,
      })

      downloading.current = false
    }, 45 * 1000)

    // return () => clearTimeout(timeout)
  }

  useEffect(() => {
    async function handleOnlineStatusChange() {
      clearTimeout(timeout)

      if (onlineStatus) {
        console.log("Now online")
        await dispatch(authInit())
      } else {
        console.log("Now offline")
        await dispatch(authInitOffline())
      }

      // Always load catalog from localStorage
      const products = await storageGet<Product[]>(StoragePrefix.CATALOG, "products")
      const categories = await storageGet<Category[]>(StoragePrefix.CATALOG, "categories")
      const tags = await storageGet<Tag[]>(StoragePrefix.CATALOG, "tags")
      if (products && categories && tags) {
        await dispatch(setCatalog({ products, categories, tags }))
        notification.warning({
          message: t("messages.catalog.fetchedFromLocalStorage"),
          description: t("messages.catalog.offlineReady"),
          duration: 10,
        })
      } else {
        notification.error({
          message: t("messages.catalog.notAvailable"),
          duration: null,
        })
      }
    }
    handleOnlineStatusChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStatus, auth, dispatch])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div className="min-h-full">
        {!auth.ready && <div>{t("app.auth.loading")}</div>}
        {auth.ready && (
          <>
            {!onlineStatus && (
              <div className="px-3 py-1 text-sm bg-yellow-300">
                <b>{t("app.offline.warning")}</b>
              </div>
            )}
            {error && <div className="border-2 border-red-500"></div>}

            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-40 flex xl:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 pt-2 -mr-12">
                        <button
                          type="button"
                          className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">
                            {t("main.sidebar.close")}
                          </span>
                          <XIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex items-center flex-shrink-0 px-4">
                      <span className="w-full text-2xl italic font-extrabold text-center">
                        <img
                          src="/images/logo-black-sidebar.png"
                          alt="innovaliving dark logo"
                        ></img>
                      </span>
                    </div>
                    <div className="flex-1 h-0 mt-5 overflow-y-auto">
                      <nav className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <SidebarItem
                            to={item.to}
                            key={item.name}
                            name={item.name}
                            icon={item.icon}
                            setSidebarOpen={setSidebarOpen}
                          />
                        ))}
                      </nav>
                    </div>
                    <LanguageSelector></LanguageSelector>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="z-50 hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 lg:shadow">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex flex-col flex-grow pt-5 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <span className="w-full text-2xl italic font-extrabold text-center">
                    <img
                      src="/images/logo-black-sidebar.png"
                      alt="innovaliving dark logo"
                    ></img>
                  </span>
                </div>
                <div className="flex flex-col flex-1 h-full mt-5">
                  <nav className="flex-1 px-2 pb-4">
                    {navigation.map((item) => (
                      <SidebarItem
                        to={item.to}
                        key={item.name}
                        name={item.name}
                        icon={item.icon}
                        setSidebarOpen={setSidebarOpen}
                      />
                    ))}
                  </nav>
                  <LanguageSelector></LanguageSelector>
                </div>
              </div>
            </div>
            <div className="relative flex flex-col flex-1 min-h-screen lg:pl-64">
              <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 shadow bg-mygreen-500">
                <button
                  type="button"
                  className="px-4 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white lg:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">{t("main.sidebar.open")}</span>
                  <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
                </button>
                <div className="flex justify-between flex-1 px-4">
                  <div className="flex flex-1"></div>
                  <div className="flex items-center justify-center ml-4 text-sm md:ml-6">
                    {onlineStatus && <Menu as="div" className="relative ml-6">
                      <Menu.Button className="rounded-full">
                        <span
                          onClick={() =>
                            window.confirm(t("actions.syncCatalog"))
                              ? emptyCacheAndDownloadCatalog()
                              : void 0
                          }
                          className="block w-6 h-6 overflow-hidden rounded-full"
                        >
                          <RefreshIcon className="text-black"></RefreshIcon>
                        </span>
                      </Menu.Button>
                    </Menu>}
                    {!!cart.rows.length && (
                      <Link to={routes.cart} className="relative ml-6">
                        <ShoppingCartIcon className="w-6 h-6" />
                        <div className="absolute w-3 h-3 bg-red-500 rounded-full -top-1 -right-1"></div>
                      </Link>
                    )}
                    {!!pendingOrders.orders.length && (
                      <Link to={routes.pendingOrders} className="ml-6">
                        <Badge count={pendingOrders.orders.length}>
                          <ClipboardListIcon className="w-6 h-6" />
                        </Badge>
                      </Link>
                    )}

                    <Menu as="div" className="relative ml-6">
                      <div>
                        <Menu.Button className="rounded-full">
                          <span className="block w-6 h-6 overflow-hidden rounded-full">
                            <Link to={routes.user.profile}>
                              <UserIcon className="text-black"></UserIcon>
                            </Link>
                          </span>
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>

              <main className="relative flex flex-col flex-grow">
                <RouterView />
              </main>
            </div>
          </>
        )}
      </div>
    </>
  )
}

interface CheckedRouteProps extends RouteProps {
  auth?: boolean
  online?: boolean
  catalog?: boolean
}

function CheckedRoute({ auth, online, catalog, ...props }: CheckedRouteProps) {
  const authState = useAppSelector((state) => state.auth)
  const onlineStatus = useOnlineStatus()
  const catalogState = useAppSelector((state) => state.catalog)
  const history = useHistory()
  const { t } = useTranslation()

  if (online && !onlineStatus) {
    return <>{t("checkedRoute.error.offline")}</>
  }
  if (onlineStatus && auth && !authState.loggedIn) {
    history.push(routes.auth.login)
    return <></>
  }
  const catalogIsValid = catalogState.products
  if (catalog && !catalogIsValid) {
    return <>{t("checkedRoute.error.catalog")}</>
  }

  return <Route {...props} />
}

function RouterView() {
  return (
    <Switch>
      <CheckedRoute auth exact path={routes.index} component={Categories} />
      <CheckedRoute catalog auth exact path={routes.cart} component={Cart} />
      <CheckedRoute
        catalog
        auth
        exact
        path={routes.pendingOrders}
        component={PendingOrders}
      />
      <CheckedRoute
        catalog
        auth
        exact
        path={routes.products.index}
        component={ProductsIndex}
      />
      <CheckedRoute
        catalog
        auth
        path={routes.products.show}
        component={ProductsShow}
      />
      <CheckedRoute
        online
        exact
        path={routes.auth.login}
        component={AuthLogin}
      />
      <CheckedRoute
        online
        auth
        path={routes.user.profile}
        component={AuthLogout}
      />
      <Route>404</Route>
    </Switch>
  )
}

interface SidebarItemProps {
  to: string
  name: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  setSidebarOpen: Function
}

function SidebarItem(props: SidebarItemProps) {
  let match = useRouteMatch({
    path: props.to,
    exact: true,
  })
  return (
    <Link
      to={props.to}
      key={props.name}
      onClick={() => props.setSidebarOpen(false)}
    >
      <div
        className={classNames(
          match
            ? "bg-mygreen-700 text-white"
            : "hover:bg-mygreen-300 text-black",
          "group flex items-center px-2 py-2 text-base font-medium rounded-md my-1",
        )}
      >
        <props.icon
          className={classNames(
            match ? "text-white" : "text-mygreen-700",
            "flex-shrink-0 w-6 h-6 mr-4",
          )}
          aria-hidden="true"
        />
        {props.name}
      </div>
    </Link>
  )
}

function LanguageSelector() {
  return (
    <div className="flex flex-row items-center m-4 space-x-6">
      <label htmlFor="locale" className="text-sm font-medium text-gray-700">
        {t("sidebar.language")}
      </label>
      <select
        id="locale"
        name="locale"
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        className="flex-grow py-2 pl-3 pr-10 mt-1 text-base text-white rounded-md bg-mygreen-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        {Object.keys(i18n.services.resourceStore.data).map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  )
}

export default App
