import { useAppDispatch, useAppSelector } from "../../hooks"
import classNames from "classnames"
import { useEffect, useState } from "react"
import priceFormatter from "../../utils/priceFormatter"
import { useTranslation } from "react-i18next"
import { generatePath, Link, useLocation } from "react-router-dom"
import routes from "../../routes"
import i18n from "../../i18n"
import { SearchIcon } from "@heroicons/react/outline"
import React from "react"
import { filterProducts, loadMore } from "../../store/catalogSlice"
import { buildImageUrl, getCrossOriginSetting } from "../../utils/images"
import { setPageScroll, setPaginationItems } from "../../store/pageScrollSlice"
import { StarIcon } from "@heroicons/react/solid"
import config from "../../config"

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Index() {
  const { t } = useTranslation()
  const catalog = useAppSelector((state) => state.catalog)
  // Display products in a grid
  const dispatch = useAppDispatch()
  const query = useQuery()

  const scroll = useAppSelector((state) => state.pageScroll.pageScroll ?? 0)
  useEffect(() => {
    if (query.has("restore-scroll"))
      window.scrollTo({
        left: 0,
        top: scroll,
      })
    dispatch(setPageScroll(0))

    const saveScrollPosition = () => {
      dispatch(setPageScroll(window.scrollY))
      dispatch(
        setPaginationItems(
          document.getElementById("product-list")?.children.length ?? 0,
        ),
      )
    }
    window.addEventListener("scroll", saveScrollPosition, { passive: true })
    return () => window.removeEventListener("scroll", saveScrollPosition)
    // eslint-disable-next-line
  }, [])
  const [loading, setLoading] = useState(false)
  const infiniteScroll = () => {
    const offset = document
      .getElementById("products-container")
      ?.getBoundingClientRect().bottom as number
    if (window.outerHeight >= offset - 100 && !loading) {
      setLoading(true)
      void dispatch(loadMore())
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", infiniteScroll, { passive: true })
    return () => window.removeEventListener("scroll", infiniteScroll)
    // eslint-disable-next-line
  }, [])
  useEffect(() => setLoading(false), [catalog.filteredProducts])

  // Pre-select category if coming from the home
  const selectedCategory = query.get("selectedcategory")
  let defaultSelectedCategories = catalog.filters.categories
    ? [...catalog.filters.categories]
    : []
  if (selectedCategory) {
    try {
      const catId = parseInt(selectedCategory)
      defaultSelectedCategories.push(catId)
    } catch {}
  }

  const [filterText, setFilterText] = useState(catalog.filters.text ?? "")

  const [selectedCategories, setSelectedCategories] = useState<number[]>(
    defaultSelectedCategories,
  )
  const toggleCategory = (catId: number) => {
    const cats = [...selectedCategories]
    const index = cats.indexOf(catId)
    if (index >= 0) cats.splice(index, 1)
    else cats.push(catId)

    setSelectedCategories(cats)
  }

  const [selectedTags, setSelectedTags] = useState<number[]>(
    catalog.filters.tags ?? [],
  )
  const toggleTag = (tagId: number) => {
    const index = selectedTags.indexOf(tagId)
    const tags = [...selectedTags]
    if (index >= 0) tags.splice(index, 1)
    else tags.push(tagId)

    setSelectedTags(tags)
  }

  const [onlyAvailable, setOnlyAvailable] = useState(false)

  const prevLength = useAppSelector(
    (state) => state.pageScroll.paginationItems ?? 0,
  )
  useEffect(() => {
    if (query.get("selectedcategory")) {
      setFilterText("")
      setSelectedCategories([Number(query.get("selectedcategory"))])
      setSelectedTags([])
    }
    //eslint-disable-next-line
  }, [])
  useEffect(
    () =>
      void dispatch(
        filterProducts({
          categories: selectedCategories,
          tags: selectedTags,
          text: filterText,
          onlyAvailable,
          prevLength,
        }),
      ),
    [
      selectedCategories,
      selectedTags,
      filterText,
      onlyAvailable,
      dispatch,
      prevLength,
    ],
  )

  return (
    <div>
      <details
        open={true}
        className="sticky z-40 p-4 pb-0 border-b bg-gray-50 top-16"
      >
        <summary className="mb-4">{t("products.index.filters")}</summary>
        <div className="mb-3">
          <label className="block mb-2 text-xl">
            {t("products.index.search")}
          </label>
          <div className="flex gap-4">
            <div className="relative flex-grow mt-1 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="block w-full h-10 pl-10 text-lg border border-gray-200 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Cerca..."
              />
            </div>
            <button
              className={
                "h-10 w-10 mt-1 bg-mygreen-500 rounded-full " +
                (!onlyAvailable ? "opacity-25" : "")
              }
              onClick={() => setOnlyAvailable(!onlyAvailable)}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="inline mb-1 text-xl">
            {t("products.index.categories")}
          </div>
          {selectedCategories.length > 0 && (
            <>
              <button
                type="button"
                className="px-2 ml-4 bg-gray-200 rounded-full"
                onClick={() => setSelectedCategories([])}
              >
                {t("products.index.resetCategories")}
              </button>
            </>
          )}
          <div className="px-2 py-2 space-x-3 overflow-auto whitespace-nowrap">
            {Array.from(catalog.categories ?? []).map((category) => (
              <span
                className={classNames(
                  "inline-flex items-center cursor-pointer rounded-full text-sm font-bold",
                  {
                    "bg-gray-100 text-gray-800": !selectedCategories.includes(
                      category.id,
                    ),
                    "bg-mygreen-300 text-mygreen-800 outline-2 outline outline-mygreen-800":
                      selectedCategories.includes(category.id),
                  },
                )}
                key={category.id}
                onClick={() => toggleCategory(category.id)}
              >
                <span>
                  <img
                    alt=""
                    src={buildImageUrl(category.photo)}
                    loading="lazy"
                    crossOrigin={getCrossOriginSetting()}
                    className={classNames("w-10 h-10 rounded-full", {
                      "outline outline-mygreen-800 outline-2":
                        selectedCategories.includes(category.id),
                    })}
                  ></img>
                </span>
                <span className="py-1 pl-2 pr-3">
                  {category.name[i18n.language]}
                </span>
              </span>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <div className="inline mb-1 text-xl">{t("products.index.tags")}</div>
          {selectedTags.length > 0 && (
            <>
              <button
                type="button"
                className="px-2 ml-4 bg-gray-200 rounded-full"
                onClick={() => setSelectedTags([])}
              >
                {t("products.index.resetTags")}
              </button>
            </>
          )}
          <div className="px-2 py-2 mb-4 space-x-2 overflow-auto rounded whitespace-nowrap">
            {Array.from(catalog.tags ?? []).map((tag) => (
              <span
                className={classNames(
                  "inline-flex items-center px-3 py-1 cursor-pointer rounded-full text-sm font-bold",
                  {
                    "bg-gray-100 text-gray-800": !selectedTags.includes(tag.id),
                    "bg-mygreen-300 text-mygreen-800": selectedTags.includes(
                      tag.id,
                    ),
                  },
                )}
                key={tag.id}
                onClick={() => toggleTag(tag.id)}
              >
                {tag.name[i18n.language]}
              </span>
            ))}
          </div>
        </div>
      </details>

      <div id="products-container" className="pb-8 m-4">
        <div className="mb-1 text-xl">
          {t("products.index.products", {
            count: catalog.filteredProducts?.length,
          })}
        </div>
        <ul
          id="product-list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:gap-x-8"
        >
          {catalog.filteredProducts?.map((product) => (
            <Link
              to={generatePath(routes.products.show, { id: product.id })}
              key={product.id}
              className="relative group"
            >
              <div className="block w-full overflow-hidden bg-gray-100 rounded-lg aspect-w-8 aspect-h-8 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                <img
                  src={buildImageUrl(product.cover_photo)}
                  loading="lazy"
                  crossOrigin={getCrossOriginSetting()}
                  alt={product.name[i18n.language]}
                  className="object-scale-down object-center pointer-events-none group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">
                    View details for {product.name[i18n.language]}
                  </span>
                </button>
              </div>
              {(product.availability[i18n.language] === "Available" ||
                product.availability[i18n.language] === "Disponibile" ||
                product.special_price) && (
                <div className="absolute flex items-center gap-1 right-2 top-2">
                  {product.special_price && (
                    <StarIcon className="w-3.5 h-3.5 rounded-full shadow-sm text-yellow-500"></StarIcon>
                  )}
                  {(product.availability[i18n.language] === "Available" ||
                    product.availability[i18n.language] === "Disponibile") && (
                    <div className="w-2 h-2 rounded-full shadow-sm bg-mygreen-500"></div>
                  )}
                </div>
              )}
              <p className="block mt-2 mb-0 text-sm font-medium text-gray-900 truncate pointer-events-none">
                {product.name[i18n.language]}
              </p>
              <p className="block text-xs text-gray-400">{product.code}</p>
              <p className="block text-sm font-medium text-gray-600 pointer-events-none">
                <span className={product.special_price ? " line-through" : ""}>
                  {priceFormatter.format(product.price)}
                </span>
                &nbsp;
                {product.special_price
                  ? priceFormatter.format(product.special_price)
                  : ""}
              </p>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}
