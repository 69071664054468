import config from "../config"
import { Category, Product } from "../models/Product"

export const buildImageUrl = (url: string) => {
  // Append API base URL if it's a relative path
  if (!url || url.startsWith("http")) {
    return url
  }
  return config.apiHost + url
}

function _downloadImages (photoUrls: string[]) {
  let imageList: HTMLImageElement[] = []

  for (const url of photoUrls) {
    let img: HTMLImageElement | null = new Image()
    img.onload = function () {
      var index = imageList.indexOf(img as HTMLImageElement);
      if (index !== -1) {
        // remove image from the array once it's loaded
        // for memory consumption reasons
        imageList.splice(index, 1);
      }
    }
    imageList.push(img)
    img.crossOrigin = getCrossOriginSetting() || null // Include "Origin" header
    img.decoding = "async"
    img.src = url // This statement starts the download
  }
}

async function _getPathsNotCached(paths: string[], logNonCached: boolean = false) {
  const imageCache = await caches.open("images-0")
  const cachedUrls = (await imageCache.keys()).map((request) => request.url)
  const notCached = paths.filter((url) => !cachedUrls.includes(url))
  if (logNonCached) {
    console.log("not cached: ")
    console.log(notCached)
  }
  return notCached
}

function _getImagesFullPaths(products: Product[], categories: Category[]) {
  let paths = []
  for (const category of categories) {
    paths.push(category.photo)
  }

  for (const product of products) {
    paths.push(product.cover_photo)
  }

  for (const product of products) {
    for (const photo of product.photos) {
      paths.push(photo.path)
    }
  }

  return paths.map((path) => buildImageUrl(path))
}

export function precacheImages (products: Product[], categories: Category[]) {
  const fullPaths = _getImagesFullPaths(products, categories)
  _downloadImages(fullPaths)
}

export async function checkCachedImages (products: Product[], categories: Category[], logNonCached: boolean = false) {
  const fullPaths = _getImagesFullPaths(products, categories)
  const missedPaths = await _getPathsNotCached(fullPaths, logNonCached)
  _downloadImages(missedPaths)
}

export function getCrossOriginSetting() {
  return config.apiHost.startsWith("http://localhost")
    ? undefined
    // : "anonymous"
    : undefined
}