import { t } from "i18next"
import { Link } from "react-router-dom"
import { useAppSelector } from "../hooks"
import i18n from "../i18n"
import routes from "../routes"
import { buildImageUrl, getCrossOriginSetting } from "../utils/images"

export default function Categories() {
  const categories = useAppSelector((state) => state.catalog.categories)

  return (
    <>
      <div className="bg-white">
        <div className="py-8 sm:py-12">
          <h1 className="mb-12 text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
            {t("categories.title")}
          </h1>
          <div className="grid grid-cols-2 gap-6 mx-4 mt-4 md:grid-cols-3 xl:grid-cols-4">
            {categories &&
              categories.map((category) => (
                <Link
                  key={category.id}
                  className="relative flex flex-col w-full p-6 mx-auto overflow-hidden rounded-lg shadow-lg aspect-1 hover:opacity-90 hover:cursor-pointer"
                  to={{
                    pathname: routes.products.index,
                    search: `?selectedcategory=${category.id}`,
                  }}
                >
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src={buildImageUrl(category.photo)}
                      crossOrigin={getCrossOriginSetting()}
                      alt=""
                      className="object-cover object-center w-full h-full"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 opacity-50 h-2/3 bg-gradient-to-t from-gray-800"
                  />
                  <span className="relative mt-auto text-xl font-bold text-center text-white">
                    {category.name[i18n.language]}
                  </span>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}
