import { CartOrder } from "../store/cartSlice"
import { OrderIntent } from "../store/pendingOrdersSlice"
import { post as apiPost } from "./API"

export function send(order: CartOrder | OrderIntent) {
  const CUSTOMER = 4418
  return apiPost(
    "/orders",
    {
      order_rows: order.rows.map((el) => ({
        id: el.productId,
        qty: el.quantity,
      })),
      user_id: CUSTOMER,
      note: `${order.customer}|${order.delivery}|${order.payment}|${order.sdi}|${order.notes}`,
    },
    { authenticated: true },
  )
}
