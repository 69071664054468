import { configureStore } from "@reduxjs/toolkit"
import auth from "./authSlice"
import cart from "./cartSlice"
import catalog from "./catalogSlice"
import error from "./errorSlice"
import pendingOrders from "./pendingOrdersSlice"
import pageScroll from "./pageScrollSlice"

export const store = configureStore({
  reducer: {
    catalog,
    error,
    auth,
    cart,
    pendingOrders,
    pageScroll,
  },
})
export default store

// https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
