import { notification } from "antd"
import { t } from "i18next"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../hooks"
import routes from "../../routes"
import { logout as authLogout } from "../../store/authSlice"

export default function Logout() {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const logout = async () => {
    await dispatch(authLogout())
    notification.success({
      message: t("messages.logout"),
    })
    history.replace(routes.index)
  }

  return (
    <>
      <div className="flex flex-grow flex-col items-center justify-center min-h-full pt-4 pb-16 sm:px-6 lg:px-8">
        <img
          src="/images/logo-black-sidebar.png"
          className="mx-auto mb-16 w-96"
          alt="innovaliving dark logo"
        ></img>
        <button
          type="button"
          onClick={logout}
          className="flex justify-center px-4 py-2 mx-auto text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-700 w-72 hover:bg-mygreen-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t("auth.logout")}
        </button>
      </div>
    </>
  )
}
