import { useEffect, useState } from "react"

export function isOnline(): boolean {
  return typeof navigator.onLine === "boolean" ? navigator.onLine : true
}

export default function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(isOnline())

  const goOnline = () => setOnlineStatus(true)

  const goOffline = () => setOnlineStatus(false)

  useEffect(() => {
    window.addEventListener("online", goOnline)
    window.addEventListener("offline", goOffline)

    return () => {
      window.removeEventListener("online", goOnline)
      window.removeEventListener("offline", goOffline)
    }
  }, [])

  return onlineStatus
}
