import { useAppDispatch, useAppSelector } from "../hooks"
import { FunctionComponent, HTMLAttributes, MouseEvent, useState } from "react"
import { Alert, notification } from "antd"
import { deleteOrder, OrderIntent } from "../store/pendingOrdersSlice"
import useOnlineStatus from "../hooks/useOnlineStatus"
import {
  CheckIcon,
  ClockIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/outline"
import { useDispatch } from "react-redux"
import { findProduct } from "../store/catalogSlice"
import priceFormatter from "../utils/priceFormatter"
import {
  addToCart as addToCartAction,
  clearCart as clearCartAction,
  setCustomer,
  setDelivery,
  setPayment,
  setSdi,
  setNotes,
} from "../store/cartSlice"
import { t } from "i18next"
import { generatePath, useHistory } from "react-router"
import routes from "../routes"
import { send } from "../api/Order"
import i18n from "../i18n"
import { getProductPrice } from "../utils/getProductPrice"
import { Product } from "../models/Product"
import { getCrossOriginSetting } from "../utils/images"

interface PendingOrderProps extends HTMLAttributes<HTMLElement> {
  pendingOrder: OrderIntent
  deletePendingOrder: Function
  i: number
}
const PendingOrder: FunctionComponent<PendingOrderProps> = ({
  pendingOrder,
  deletePendingOrder,
  i,
  ...props
}) => {
  const onlineStatus = useOnlineStatus()
  const history = useHistory()
  // const notes = `${pendingOrder.customer}|${pendingOrder.delivery}|${pendingOrder.payment}|${pendingOrder.sdi}`
  const catalogState = useAppSelector((state) => state.catalog)
  const dateFormatter = (datestring: string) => {
    return new Date(datestring).toUTCString()
  }

  const [invalidProducts, setInvalidProducts] = useState<number[]>([])
  const dispatch = useDispatch()
  const edit = () => {
    if (window.confirm(t("cart.confirmEditOrder"))) {
      dispatch(clearCartAction())
      pendingOrder.rows.forEach((r) => {
        dispatch(
          addToCartAction({
            product: r.productId,
            quantity: r.quantity,
            name: r.name,
            code: r.code,
          }),
        )
      })
      dispatch(setCustomer(pendingOrder.customer))
      dispatch(setDelivery(pendingOrder.delivery))
      dispatch(setPayment(pendingOrder.payment))
      dispatch(setSdi(pendingOrder.sdi))
      dispatch(setNotes(pendingOrder.notes))
      notification.success({ message: t("cart.addedToCart") })
      deletePendingOrder(i)
      history.push(generatePath(routes.cart))
    }
  }
  const sendOrder = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (window.confirm(t("cart.confirmSendOrder"))) {
      send(pendingOrder).then((res) => {
        if (res.errors) {
          alert(
            Object.keys(res.errors).map((err) => {
              const rowNumber = res.errors[err][0].match(
                /.*order_rows\.(.*)\.id.*/,
              )?.[1]
              const product = findProduct(
                catalogState,
                pendingOrder.rows[rowNumber].productId,
              )
              return product
                ? `${product?.name[i18n.language]} ${t(
                    "pendingOrder.productUnavailable",
                  )}`
                : `${pendingOrder.rows[rowNumber].name[i18n.language]} ${t(
                    "pendingOrder.productUnavailable",
                  )}`
            }),
          )
          setInvalidProducts(
            Object.keys(res.errors)
              .map(
                (err) =>
                  res.errors[err][0].match(
                    /The selected order_rows\.(.*)\.id is invalid\./,
                  )?.[1],
              )
              .map((row) => pendingOrder.rows[row].productId),
          )
        } else {
          deletePendingOrder(i)
          notification.info({
            message: t("messages.orderSent"),
            duration: 4,
          })
        }
      })
    }
  }
  return (
    <>
      <div>
        <div
          className="relative px-6 py-4 space-y-1 bg-white rounded-lg shadow"
          {...props}
        >
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <div className="flex flex-row items-center space-x-1 font-medium text-gray-600">
                <ClockIcon className="w-4 h-4"></ClockIcon>
                <span>{dateFormatter(pendingOrder.created_at)}</span>
              </div>
              <div>Order {pendingOrder.uuid}</div>
              <div className="max-w-3/4">
                {pendingOrder.customer}, {pendingOrder.delivery},{" "}
                {pendingOrder.payment}, {pendingOrder.sdi}
              </div>
            </div>
            <div className="text-xl font-medium text-gray-900">
              {priceFormatter.format(
                pendingOrder.rows
                  .map(({ productId, quantity }) => ({
                    product: findProduct(catalogState, productId) as Product,
                    quantity,
                  }))
                  .reduce(
                    (acc, r) =>
                      acc +
                      (r.product ? getProductPrice(r.product, r.quantity) : 0) *
                        r.quantity,
                    0,
                  ),
              )}
            </div>
          </div>
          <details>
            <summary>
              {pendingOrder.rows
                .map((r) => r.quantity)
                .reduce((p, c) => p + c, 0)}{" "}
              items
            </summary>
            <ul className="text-sm font-medium text-gray-500 border-gray-200 divide-y divide-gray-200">
              {pendingOrder.rows.map((r) => {
                const product = findProduct(catalogState, r.productId)
                if (!product)
                  return (
                    <li key={r.productId} className="flex py-6 space-x-6">
                      <div className="relative flex items-center flex-none gap-1">
                        <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                        {t("product.notFound")} ({r.name[i18n.language]} -{" "}
                        {r.code})
                      </div>
                    </li>
                  )
                return (
                  <li key={r.productId} className="flex py-6 space-x-6">
                    <div className="relative flex-none">
                      <img
                        src={product.cover_photo}
                        crossOrigin={getCrossOriginSetting()}
                        alt=""
                        className="object-cover object-center w-12 h-12 bg-gray-100 rounded-md"
                      />
                      {invalidProducts.includes(r.productId) && (
                        <div className="absolute w-2 h-2 bg-red-500 rounded-full top-2 right-2"></div>
                      )}
                    </div>
                    <div className="flex-auto space-y-1">
                      <h3 className="text-gray-900">{product.name.it}</h3>
                      <p>{r.quantity} items</p>
                    </div>
                    <p className="flex-none font-medium text-gray-900">
                      {priceFormatter.format(
                        getProductPrice(product, r.quantity) * r.quantity,
                      )}
                    </p>
                  </li>
                )
              })}
            </ul>
          </details>
          <div className="flex justify-end pt-2 space-x-4">
            <button
              type="button"
              onClick={() =>
                window.confirm(t("pendingOrder.confirmDelete"))
                  ? deletePendingOrder(i)
                  : void 0
              }
              className="inline-flex items-center py-2 pl-2 pr-4 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <TrashIcon className="w-6 h-6 mr-2"></TrashIcon>
              <span>{t("pendingOrder.delete")}</span>
            </button>
            <button
              type="button"
              className="inline-flex items-center py-2 pl-2 pr-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-500 hover:bg-mygreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={edit}
            >
              <PencilIcon className="w-6 h-6 mr-2"></PencilIcon>
              <span>{t("pendingOrder.edit")}</span>
            </button>
            <button
              type="button"
              className="inline-flex items-center py-2 pl-2 pr-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-500 hover:bg-mygreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              onClick={sendOrder}
              disabled={!onlineStatus}
            >
              <CheckIcon className="w-6 h-6 mr-2"></CheckIcon>
              <span>{t("pendingOrder.submit")}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default function PendingOrders() {
  const onlineStatus = useOnlineStatus()
  const dispatch = useAppDispatch()
  const pendingOrders = useAppSelector((state) => state.pendingOrders)
  const deletePendingOrder = async (index: number) => {
    dispatch(deleteOrder({ index }))
  }
  return (
    <div className="bg-gray-50">
      <div className="max-w-2xl px-4 pt-8 pb-16 mx-auto sm:pb-24 sm:pt-16 sm:px-6 lg:px-0">
        <h1 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
          {t("pendingOrder.title")}
        </h1>
        {!onlineStatus && <Alert type="error" showIcon message="Offline" />}
        <div className="mt-8 space-y-4">
          {pendingOrders.orders.map((pendingOrder, i) => (
            <PendingOrder
              pendingOrder={pendingOrder}
              key={i}
              i={i}
              deletePendingOrder={deletePendingOrder}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
