import { useAppDispatch, useAppSelector } from "../hooks"
import {
  OrderRow,
  clearCart as clearCartAction,
  removeCartItem,
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  setCustomer,
  setDelivery,
  setPayment,
  setSdi,
  setNotes,
} from "../store/cartSlice"
import { send } from "../api/Order"
import { FunctionComponent, HTMLAttributes, MouseEvent, useState } from "react"
import { findProduct } from "../store/catalogSlice"
import { Alert, Input, notification } from "antd"
import { addOrder } from "../store/pendingOrdersSlice"
import { v4 as uuidv4 } from "uuid"
import { useHistory } from "react-router-dom"
import routes from "../routes"
import i18n from "../i18n"
import priceFormatter from "../utils/priceFormatter"
import { t } from "i18next"
import { buildImageUrl, getCrossOriginSetting } from "../utils/images"
import { getProductPrice } from "../utils/getProductPrice"
import useOnlineStatus from "../hooks/useOnlineStatus"

interface CartRowProps extends HTMLAttributes<HTMLElement> {
  row: OrderRow
  index: number
  removeCartRow: Function
  decreaseCartRowQuantity: Function
  increaseCartRowQuantity: Function
  error: boolean
}
const CartRow: FunctionComponent<CartRowProps> = ({
  row,
  index,
  removeCartRow,
  decreaseCartRowQuantity,
  increaseCartRowQuantity,
  error,
}) => {
  const catalog = useAppSelector((state) => state.catalog)
  const product = findProduct(catalog, row.productId)
  return (
    <>
      {!product && (
        <div>
          <div className="flex items-center gap-1">
            <div className="w-2 h-2 bg-red-500 rounded-full"></div>
            {t("product.notFound")} ({row.name[i18n.language]} - {row.code})
          </div>
          <button
            type="button"
            onClick={() => removeCartRow(index)}
            className="text-sm font-medium text-mygreen-800 hover:text-mygreen-700"
          >
            {t("cart.removeEntry")}
          </button>
        </div>
      )}
      {product && (
        <li key={Math.random()} className="flex py-6">
          <div className="relative flex-shrink-0">
            <img
              src={buildImageUrl(product?.cover_photo)}
              crossOrigin={getCrossOriginSetting()}
              alt=""
              className="object-contain object-center w-24 h-24 rounded-md sm:w-32 sm:h-32"
            />
            {error && (
              <div className="absolute w-2 h-2 bg-red-500 rounded-full top-2 right-2"></div>
            )}
          </div>

          <div className="flex flex-col flex-1 mt-4 ml-4 sm:ml-6">
            <div>
              <div className="flex justify-between">
                <h4 className="text-xl font-medium text-gray-700 hover:text-gray-800">
                  {product.name[i18n.language]}
                </h4>
                <p className="ml-4 font-medium text-gray-900 text-md">
                  {priceFormatter.format(
                    getProductPrice(product, row.quantity),
                  )}{" "}
                  x {row.quantity}
                </p>
              </div>
            </div>
            <div className="text-gray-500">{product.code}</div>
            <div className="flex items-end justify-between flex-1 my-4 text-left">
              <button
                type="button"
                onClick={() =>
                  window.confirm(t("cart.confirmRemoveEntry"))
                    ? removeCartRow(index)
                    : void 0
                }
                className="text-sm font-medium text-mygreen-800 hover:text-mygreen-700"
              >
                {t("cart.removeEntry")}
              </button>
              <div>
                {row.quantity > product.lot_size && (
                  <>
                    <button
                      type="button"
                      onClick={() =>
                        decreaseCartRowQuantity(index, product.lot_size)
                      }
                      className="text-sm font-medium text-mygreen-800 hover:text-mygreen-700"
                    >
                      {t("cart.decreaseQuantity")}
                    </button>
                    <span className="mx-4">|</span>
                  </>
                )}
                <button
                  type="button"
                  onClick={() =>
                    increaseCartRowQuantity(index, product.lot_size)
                  }
                  className="text-sm font-medium text-mygreen-800 hover:text-mygreen-700"
                >
                  {t("cart.increaseQuantity")}
                </button>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  )
}

export default function Cart() {
  const cart = useAppSelector((state) => state.cart)
  const catalog = useAppSelector((state) => state.catalog)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const clearCart = async () => {
    notification.info({
      message: t("messages.cartCleared"),
      duration: 4,
    })
    dispatch(clearCartAction())
  }

  const confirmOrder = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (!e.currentTarget.closest("form")?.reportValidity()) return
    dispatch(
      addOrder({
        ...cart,
        uuid: uuidv4(),
        created_at: new Date().toISOString(),
      }),
    )
    dispatch(clearCartAction())
    notification.info({
      message: t("messages.orderConfirmed"),
      duration: 4,
    })
    history.replace(routes.pendingOrders)
  }

  const [invalidProducts, setInvalidProducts] = useState<number[]>([])

  const sendOrder = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (!e.currentTarget.closest("form")?.reportValidity()) return
    send(cart).then((res) => {
      if (res.errors) {
        alert(
          Object.keys(res.errors).map((err) => {
            const rowNumber = res.errors[err][0].match(
              /.*order_rows\.(.*)\.id.*/,
            )?.[1]
            const product = findProduct(catalog, cart.rows[rowNumber].productId)
            return product
              ? `${product?.name[i18n.language]} ${t(
                  "pendingOrder.productUnavailable",
                )}`
              : `${cart.rows[rowNumber].name[i18n.language]} ${t(
                  "pendingOrder.productUnavailable",
                )}`
          }),
        )
        setInvalidProducts(
          Object.keys(res.errors)
            .map(
              (err) =>
                res.errors[err][0].match(
                  /The selected order_rows\.(.*)\.id is invalid\./,
                )?.[1],
            )
            .map((row) => cart.rows[row].productId),
        )
      } else {
        notification.info({
          message: t("messages.orderSent"),
          duration: 4,
        })
        dispatch(clearCartAction())
      }
    })
  }

  const removeCartRow = async (index: number) => {
    dispatch(removeCartItem({ index }))
  }

  const increaseCartRowQuantity = async (index: number, lot_size: number) => {
    dispatch(increaseCartItemQuantity({ index, lot_size }))
  }

  const decreaseCartRowQuantity = async (index: number, lot_size: number) => {
    dispatch(decreaseCartItemQuantity({ index, lot_size }))
  }

  const isOnline = useOnlineStatus()
  /*
  const allProductsAvailable = cart.rows.every((r) =>
    findProduct(catalog, r.productId),
  )
  */
  return (
    <div className="flex-grow bg-white">
      <div className="max-w-2xl px-4 pt-8 pb-16 mx-auto sm:pb-24 sm:pt-16 sm:px-6 lg:px-0">
        <h1 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
          {t("shoppingCart.title")}
        </h1>

        <form className="mt-12">
          <section aria-labelledby="cart-heading">
            <h2 id="cart-heading" className="sr-only">
              {t("shoppingCart.subtitle")}
            </h2>

            <ul className="border-t border-b border-gray-200 divide-y divide-gray-200">
              {cart.rows.map((row, index) => (
                <CartRow
                  row={row}
                  index={index}
                  removeCartRow={removeCartRow}
                  increaseCartRowQuantity={increaseCartRowQuantity}
                  decreaseCartRowQuantity={decreaseCartRowQuantity}
                  key={row.productId}
                  error={invalidProducts.includes(row.productId)}
                ></CartRow>
              ))}
              {cart.rows.length === 0 && (
                <Alert message="Add products to send order" />
              )}
              <li>
                <div className="flex items-center justify-between py-6">
                  <span className="text-xl font-medium text-gray-700 hover:text-gray-800">
                    Totale:
                  </span>
                  <span className="text-2xl font-bold text-gray-700 hover:text-gray-800">
                    {priceFormatter.format(
                      cart.rows.reduce((acc, row) => {
                        const product = findProduct(catalog, row.productId)
                        return (
                          acc +
                          Number(
                            product
                              ? getProductPrice(product, row.quantity)
                              : 0,
                          ) *
                            row.quantity
                        )
                      }, 0),
                    )}
                  </span>
                </div>
              </li>
            </ul>
          </section>

          {/* Order summary */}
          <section aria-labelledby="summary-heading" className="mt-10">
            <h2 id="summary-heading" className="sr-only">
              Order summary
            </h2>
            {cart.rows.length > 0 && (
              <div className="space-y-4">
                <Input
                  value={cart.customer}
                  onChange={(e) => dispatch(setCustomer(e.target.value))}
                  placeholder="Nome cliente"
                  required
                />
                <Input
                  value={cart.delivery}
                  onChange={(e) => dispatch(setDelivery(e.target.value))}
                  placeholder="Consegna"
                  required
                />
                <Input
                  value={cart.payment}
                  onChange={(e) => dispatch(setPayment(e.target.value))}
                  placeholder="Pagamento"
                  required
                />
                <Input
                  value={cart.sdi}
                  onChange={(e) => dispatch(setSdi(e.target.value))}
                  placeholder="Codice SDI"
                  required
                />
                <Input
                  value={cart.notes}
                  onChange={(e) => dispatch(setNotes(e.target.value))}
                  placeholder="Note"
                />
              </div>
            )}

            {/* <div>
              <dl className="space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-base font-medium text-gray-900">Subtotal</dt>
                  <dd className="ml-4 text-base font-medium text-gray-900">Prezzo totale?</dd>
                </div>
              </dl>
              <p className="mt-1 text-sm text-gray-500">Shipping and taxes will be calculated at checkout.</p>
            </div> */}

            {cart.rows.length > 0 && (
              <div className="flex items-center justify-center mt-10 space-x-4">
                <button
                  type="button"
                  onClick={() =>
                    window.confirm(t("cart.confirmClearCart"))
                      ? void clearCart()
                      : void 0
                  }
                  className="w-full px-4 py-3 text-base font-medium text-white bg-red-700 border border-transparent rounded-md shadow-sm hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                >
                  {t("cart.clearCart")}
                </button>
                <button
                  type="button"
                  onClick={confirmOrder}
                  disabled={!cart.rows.length}
                  className="w-full px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-700 hover:bg-mygreen-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                >
                  {t("cart.saveForLater")}
                </button>
                {isOnline && (
                  <button
                    type="button"
                    onClick={sendOrder}
                    disabled={!cart.rows.length}
                    className="w-full px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-mygreen-700 hover:bg-mygreen-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    {t("cart.sendOrder")}
                  </button>
                )}
              </div>
            )}
          </section>
        </form>
      </div>
    </div>
  )
}
