import axios, { AxiosRequestConfig } from "axios"
import { lsGet, StoragePrefix } from "../utils/localstorage"

export function setup(baseURL: string) {
  axios.defaults.baseURL = baseURL
  axios.defaults.validateStatus = (status) =>
    (status >= 200 && status < 400) || status === 422
}

function cleanEndpoint(endpoint: string) {
  if (endpoint.startsWith("/")) {
    endpoint = endpoint.substring(1)
  }
  return endpoint
}

interface ApiRequestConfig extends AxiosRequestConfig {
  authenticated?: boolean
}

function addAuthHeaders(options: ApiRequestConfig): AxiosRequestConfig {
  if (options.authenticated) {
    delete options["authenticated"]
    const accessToken = lsGet<string>(StoragePrefix.AUTH, "accessToken")
    if (!options.headers) options.headers = {}
    options.headers["Authorization"] = `Bearer ${accessToken}`
  }

  return options
}

export async function get(endpoint: string, options: ApiRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint)

  options = addAuthHeaders(options)

  return axios
    .get(`${endpoint}`, options)
    .then((response) => {
      if (response.status === 200) {
        const expirationDate = new Date()
        expirationDate.setMinutes(expirationDate.getMinutes() + 10)
      }
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export async function post(
  endpoint: string,
  data: unknown = {},
  options: ApiRequestConfig = {},
) {
  endpoint = cleanEndpoint(endpoint)

  options = addAuthHeaders(options)

  return axios
    .post(`${endpoint}`, data, options)
    .then((response) => {
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export async function patch(
  endpoint: string,
  data: unknown = {},
  options: ApiRequestConfig = {},
) {
  endpoint = cleanEndpoint(endpoint)

  return axios
    .patch(`${endpoint}`, data, options)
    .then((response) => {
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export async function put(
  endpoint: string,
  data: unknown = {},
  options: ApiRequestConfig = {},
) {
  endpoint = cleanEndpoint(endpoint)

  return axios
    .put(`${endpoint}`, data, options)
    .then((response) => {
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export async function dele(endpoint: string, options: ApiRequestConfig = {}) {
  endpoint = cleanEndpoint(endpoint)

  return axios
    .delete(`${endpoint}`, options)
    .then((response) => {
      if (response.data.data) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}
